import React, { ReactElement } from 'react';
import cx from 'classnames';
import classes from './footer.module.scss';
import Logo from '@/assets/images/logo.svg'
import Link from 'next/link';
import { poppins, workSans } from '@/utils/fonts';

export type GridProps = {
    children?: ReactElement,
    className?: string,
    background?: { colour: string },
    text?: { colour: string },
    style?: React.CSSProperties,
}

const Footer = (props: GridProps) => {  

    const _classes = cx(classes['footer'], poppins.variable, workSans.variable, {
        [classes[`bg-${props.background?.colour}`]]: props.background?.colour,
    }, props.className);

    return (
        <section className={_classes}>
            <div className={classes['links']}>
                <div className={classes['logo']}>
                    <Link scroll={false} aria-label="eighteenyards logo" href="/">
                        <Logo />
                    </Link>
                </div>
                <ul className={classes['nav']}>
                    <li><Link scroll={false} href="/">Home</Link></li>
                    <li><Link scroll={false} href="/services">Services</Link>
                        <ul>
                            <li><Link scroll={false} href="/strategy">Strategy</Link></li>
                            <li><Link scroll={false} href="/performance">Performance</Link></li>
                            <li><Link scroll={false} href="/data">Data & Analytics</Link></li>
                            <li><Link scroll={false} href="/training">Training</Link></li>
                        </ul>
                    </li>
                    <li><Link scroll={false} href="/purpose">Purpose</Link></li>
                    <li><Link scroll={false} href="/contact">Contact</Link></li>
                </ul>
            </div>
            <div className={classes['copyright']}>
                ©eighteen yards pty ltd
            </div>
            <div className={classes['copyright']}>
                ABN 94 615 149 007
            </div>
        </section>
    )
}

export default Footer;