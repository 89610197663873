import React, { ReactElement, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import classes from './header.module.scss';
import Logo from '@/assets/images/logo.svg'
import Link from 'next/link';

export type GridProps = {
    children?: ReactElement,
    className?: string,
    background?: { colour: string },
    text?: { colour: string },
    style?: React.CSSProperties,
}

const Header = (props: GridProps) => {  

    const _classes = cx(classes['header'], {
        [classes[`bg-${props.background?.colour}`]]: props.background?.colour,
    }, props.className);

    const [menuActive, setMenuActive] = useState(false);

    return (
        <section className={_classes}>
            <div className={classes['logo-wrapper']}>
                <Link aria-label="eighteenyards logo" href="/">
                    <Logo />
                </Link>
            </div>
            <div className={classes.hamburger + " " + (menuActive ? classes['opened'] : '')} onClick={() => setMenuActive(!menuActive)}>
                <div className={classes.line}></div>
                <div className={classes.line}></div>
                <div className={classes.line}></div>
            </div>


        <div className={classes['mobile-menu'] + " " + (menuActive ? classes['active'] : '')}>
            <div className={classes['logo']}>
                <Link href="/">
                    <Logo />
                </Link>
            </div>
            <div>
                <ul className={classes['links']}>
                    <li><Link onClick={() => setMenuActive(!menuActive)} href="/">Home</Link></li>
                    <li><Link onClick={() => setMenuActive(!menuActive)} href="/services">Services</Link>
                        <ul>
                            <li><Link onClick={() => setMenuActive(!menuActive)} href="/strategy">Strategy</Link></li>
                            <li><Link onClick={() => setMenuActive(!menuActive)} href="/performance">Performance</Link></li>
                            <li><Link onClick={() => setMenuActive(!menuActive)} href="/data">Data & Analytics</Link></li>
                            <li><Link onClick={() => setMenuActive(!menuActive)} href="/training">Training</Link></li>
                        </ul>
                    </li>
                    <li><Link onClick={() => setMenuActive(!menuActive)} href="/purpose">Purpose</Link></li>
                    <li><Link onClick={() => setMenuActive(!menuActive)} href="/contact">Contact</Link></li>
                </ul>
            </div>
        </div>
        </section>
    )
}

export default Header;