import '@/styles/app.scss';
import { AnimatePresence } from 'framer-motion'
import { AppProps } from "next/app"
import { useRouter } from 'next/router'
import { useNextCssRemovalPrevention } from '@madeinhaus/nextjs-page-transition'
import SmoothScroller from '@/components/smooth-scroller/smooth-scroller'
import Grid from '@/components/grid/grid';
import Head from 'next/head';
import Header from '@/components/header/header';
import Footer from '@/components/footer/footer';
import Script from 'next/script';
import { poppins, workSans } from '@/utils/fonts';
import cx from 'classnames';

export default function App({ Component, pageProps }: AppProps) {

	const router = useRouter()
	const pageKey = router.asPath;

	const _classes = cx(poppins.variable, workSans.variable);

	useNextCssRemovalPrevention();

	return (
		<>
			<Head>
				<title>eighteen yards</title>
				<meta name="description" content="Data led sports digital marketing" />
				<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
				<link rel="icon" href="/favicon.ico" />
				<link rel="preconnect" href="https://fonts.googleapis.com"></link>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#191919" />
				<meta name="msapplication-TileColor" content="#2d89ef" />
				<meta name="theme-color" content="#191919" />

				
			</Head>
			<Script
					strategy="afterInteractive"
					src={`https://www.googletagmanager.com/gtag/js?id=GTM-WSPLF2D`}
				/>
				<Script id="google-tag-manager" strategy="afterInteractive">
					{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', 'GTM-WSPLF2D');
					`}
				</Script>
				<div className={_classes}>
					<SmoothScroller />
					<Header></Header>
					<Grid></Grid>
					<AnimatePresence mode="wait" initial={false}>
						<Component key={pageKey} {...pageProps} />
					</AnimatePresence>
					<Footer></Footer>
				</div>
		</>
	)
}
