import React from 'react';
import cx from 'classnames';
import classes from './grid.module.scss';

export type GridProps = {
    className?: string,
    background?: { colour: string },
    text?: { colour: string },
    style?: React.CSSProperties,
}

const Section = (props: GridProps) => {

    const _classes = cx(classes['section'], {
    }, props.className);

    return (
        <section className={_classes}>
            <div className={classes.column}></div>
            <div className={classes.column}><div className={classes['line']}></div></div>
            <div className={classes.column}><div className={classes['line']}></div></div>
            <div className={classes.column}><div className={classes['line']}></div></div>
            <div className={classes.column}><div className={classes['line']}></div></div>
            <div className={classes.column}><div className={classes['line']}></div></div>
        </section>
    )
}

export default Section;